.flex-gap-15 {
    gap: 15px;
}

.flex-fill-full-width {
    flex: 0 0 100%;
}

.flex-basis-30 {
    flex-basis: 30%;
}

.flex-basis-300px {
    flex-basis: 300px;
}

.flex-basis-200px {
    flex-basis: 200px;
}

@for $i from 1 through 10 {
    .flex-#{$i} {
        flex: $i;
    }
}

@for $i from 1 through 100 {
    .flex-basis-#{$i}-p {
        flex-basis: $i * 1%;
    }
}
