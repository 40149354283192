@use '_malou_variables' as *;
@use '_malou_functions' as *;

.malou-paginator {
    background: white;
    --mat-paginator-container-text-color: #{$malou-color-text-2};

    .mat-mdc-paginator-container {
        justify-content: center;
        min-height: toRem(56px);
        font-size: toRem(12px);
    }

    .mat-mdc-paginator-range-actions {
        gap: toRem(4px);

        .mat-mdc-paginator-range-label {
            display: none;
        }

        .mat-mdc-paginator-navigation-first,
        .mat-mdc-paginator-navigation-previous,
        .mat-mdc-paginator-navigation-next,
        .mat-mdc-paginator-navigation-last {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: toRem(6px);
            background-color: $malou-color-background-light;

            border: toRem(1px) solid $malou-color-background-dark;
            --mdc-icon-button-state-layer-size: 30px;
            width: toRem(30px);
            height: toRem(30px);
            padding: toRem(5px);

            --mat-paginator-enabled-icon-color: #{$malou-color-primary};
            --mat-paginator-disabled-icon-color: #{rgb($malou-color-primary, 0.3)};

            .mat-mdc-paginator-icon {
                width: toRem(20px);
                display: block;
            }

            .mat-mdc-button-touch-target {
                width: toRem(30px);
                height: toRem(30px);
            }
        }

        .custom-paginator-page {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: toRem(20px);
            height: toRem(20px);
            padding: toRem(2px);
            border-radius: toRem(6px);

            span {
                color: $malou-color-text-2;
                margin-top: toRem(4px);
            }

            &:disabled {
                background-color: $malou-color-background-dark;
                span {
                    color: $malou-color-text-1;
                    font-weight: 700;
                }
            }
        }
    }

    .mat-mdc-paginator-page-size {
        display: flex;
        align-items: center;

        .mat-mdc-paginator-page-size-label {
            color: $malou-color-text-2;
        }

        .mat-mdc-form-field-infix {
            padding: 12px 0 !important;
        }

        .mat-form-field {
            margin: 0;
        }

        .mat-form-field-wrapper {
            padding: 0;
        }

        .mat-form-field-flex {
            padding: toRem(3.5px) toRem(10px) toRem(5.5px) toRem(10px);
        }

        .mat-select-arrow {
            border: none !important;

            &::after {
                content: '';
                position: absolute;
                width: toRem(15px);
                height: toRem(10px);
                background: url('/apps/app-malou-web/src/assets/icons/chevron-down-purple.svg') no-repeat;
            }
        }

        .mat-mdc-paginator-page-size-select {
            width: toRem(77px);
        }

        .mat-select-arrow-wrapper {
            width: toRem(16px);
            transform: translateY(-25%);
        }

        .mat-select-value {
            width: unset;
        }

        .mat-select-trigger {
            justify-content: center;
            gap: toRem(4px);
        }

        .mat-select-min-line {
            color: $malou-color-text-1;
        }

        .mat-select {
            padding: 8px;
            margin-top: 4px;
        }

        .mat-form-field-appearance-outline .mat-form-field-outline {
            color: $malou-color-border-primary;
            background-color: white;
            &:active {
                color: $malou-color-border-primary;
            }
        }
    }
}
