@use '_malou_variables' as *;

.mdc-tooltip {
    --mdc-plain-tooltip-supporting-text-font: 'Poppins';
    --mdc-plain-tooltip-supporting-text-size: 10px;
    --mdc-plain-tooltip-supporting-text-weight: 500;
    --mdc-plain-tooltip-supporting-text-tracking: 0;
    --mdc-plain-tooltip-supporting-text-color: #{$malou-color-text-white};
    --mdc-plain-tooltip-container-shape: 5px;
    --mdc-plain-tooltip-container-color: #{$malou-chip-text-primary};
    --mdc-plain-tooltip-supporting-text-line-height: 14px;
}

.mdc-tooltip__surface {
    text-align: center;
    max-width: 250px;
    text-overflow: ellipsis;
}
