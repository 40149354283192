@use '_malou_variables' as *;
@use '_malou_mixins' as *;

input::placeholder {
    color: theme('colors.malou-color-text-light');
}

input:disabled::placeholder {
    color: theme('colors.malou-color-text-light/0.5');
}

// put all styles related to forms here
.malou-form {
    mat-label {
        font-variant: small-caps;
    }
}

.malou-form-flex {
    display: flex;
    flex-direction: column;
}

.malou-form-dialog {
    margin: 0;
    border: none;
    border-radius: 0;
}

.malou-checkbox__margin,
.malou-radio-button__margin {
    margin: 0 10px;
}

.malou-buttons {
    margin-top: 5px;
    button {
        margin-right: 8px;
    }
}

.malou-mat-slide {
    ::ng-deep .mat-slide-toggle-thumb {
        height: 15px !important;
        width: 15px !important;
        background-color: $malou-color-primary-blue !important;
    }

    ::ng-deep .mat-slide-toggle-bar {
        background-color: transparent !important;
        width: 40px !important;
        height: 20px !important;
        border-radius: 11px !important;
        border: 1px solid $malou-color-primary-blue !important;
    }

    ::ng-deep .mat-slide-toggle-thumb-container {
        top: 2px !important;
        left: 5px !important;
        height: 15px !important;
        width: 15px !important;
    }

    ::ng-deep .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
        transform: translate3d(16px, 0, 0) !important;
    }
}

.malou-mat-slide:not(.mat-checked) {
    ::ng-deep .mat-slide-toggle-thumb {
        background-color: $malou-color-grey-light !important;
    }

    ::ng-deep .mat-slide-toggle-bar {
        border: 1px solid $malou-color-grey-light !important;
    }
}

.malou-mat-slide-two-options {
    ::ng-deep .mat-slide-toggle-thumb {
        height: 15px !important;
        width: 15px !important;
        background-color: $malou-color-primary-blue !important;
    }

    ::ng-deep .mat-slide-toggle-bar {
        background-color: transparent !important;
        width: 40px !important;
        height: 20px !important;
        border-radius: 11px !important;
        border: 1px solid $malou-color-primary-blue !important;
    }

    ::ng-deep .mat-slide-toggle-thumb-container {
        top: 2px !important;
        left: 5px !important;
        height: 15px !important;
        width: 15px !important;
    }

    ::ng-deep .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
        transform: translate3d(16px, 0, 0) !important;
    }
}

.malou-mat-icon-search {
    transform: scale(0.9);
    margin: 0px 10px 2px 0;
    color: $malou-color-grey-light;
}

.custom-malou-form-field {
    --mdc-filled-text-field-container-color: #{$malou-color-background-light};

    .mat-mdc-form-field-infix {
        min-height: 48px;
    }

    .mat-mdc-select-disabled {
        opacity: 0.5;
        --mat-select-disabled-trigger-text-color: #{$malou-color-text-2};
    }

    &.mat-focused .mat-mdc-select-arrow {
        transform: rotate(180deg);
    }
}

.custom-malou-outlined-form-field {
    @extend .custom-malou-form-field;

    --mat-select-trigger-text-size: 12px;
    --mat-select-trigger-text-weight: 500;
    --mdc-outlined-text-field-focus-outline-color: #{$malou-color-border-primary};
    --mdc-outlined-text-field-outline-color: #{$malou-color-border-primary};
    --mdc-outlined-text-field-hover-outline-color: #{$malou-color-border-primary};
    --mat-select-enabled-trigger-text-color: #{$malou-color-text-2};
    --mat-select-placeholder-text-color: theme('colors.malou-color-text-light');

    .mdc-text-field--outlined {
        --mdc-outlined-text-field-container-shape: 8px;
        --mdc-outlined-text-field-focus-outline-width: 1px;
    }
}

.custom-malou-filled-form-field {
    @extend .custom-malou-form-field;

    --mdc-filled-text-field-container-color: #{$malou-color-transparent};
    --mat-select-trigger-text-size: 12px;
    --mat-select-trigger-text-weight: 500;
    --mat-select-enabled-trigger-text-color: #{$malou-color-text-2};
    --mat-select-placeholder-text-color: theme('colors.malou-color-text-light');

    .mdc-text-field--filled.mdc-text-field--disabled {
        --mdc-filled-text-field-disabled-container-color: #{$malou-color-transparent};
    }

    .mdc-text-field--filled {
        --mdc-filled-text-field-active-indicator-height: 0;
    }

    &.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
    &.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
        opacity: 0;
    }
}

.custom-malou-filled-form-field--no-border {
    @extend .custom-malou-filled-form-field;

    --mdc-outlined-text-field-outline-color: transparent;
    --mdc-outlined-text-field-focus-outline-color: transparent;
    --mdc-outlined-text-field-hover-outline-color: transparent;

    .mdc-text-field--filled {
        --mdc-filled-text-field-focus-active-indicator-height: 0;
    }
}
