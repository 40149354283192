@use '_malou_variables' as *;
@use '_malou_functions' as *;

::ng-deep .malou-mat-menu .mat-mdc-menu-content .mat-mdc-menu-item {
    color: $malou-color-text-2;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: toRem(12px);
    min-width: 140px;
    font-weight: 400;

    mat-icon {
        color: $malou-color-primary;
    }

    span {
        margin-right: 10px;
    }

    &:hover {
        background-color: $malou-color-background-dark !important;
        font-weight: 600;
    }
    .mat-menu-submenu-icon {
        color: rgba(0, 0, 0, 0);
        height: 18px;
        width: 18px;
        background: url('/apps/app-malou-web/src/assets/icons/arrow-forward-purple.svg') no-repeat;
        transform: scale(0.8);
        position: absolute;
        top: 15px;
        right: 10px;
    }
}

.mat-mdc-menu-item .mat-icon {
    --mat-menu-item-icon-size: 16px;
    --mat-menu-item-spacing: 16px;
}

.malou-mat-menu {
    background-color: $malou-color-white;
    max-width: fit-content !important;

    ::ng-deep &.mat-mdc-menu-panel {
        max-width: none !important;
        overflow: hidden;
        border-radius: toRem(10px);
        box-shadow: $malou-shadow-1 !important;
        background-color: $malou-color-white;
    }

    .mat-mdc-menu-content {
        padding: 0px;
    }
}

.malou-menu-item {
    margin-left: 0 !important;
    padding: 0 18px;
}

button.mat-mdc-menu-item:hover:not([disabled]) {
    background-color: $malou-color-background-dark !important;
}

.mat-menu-submenu-icon {
    color: rgba(0, 0, 0, 0);
    height: 18px !important;
    width: 18px !important;
    background: url('/apps/app-malou-web/src/assets/icons/arrow-forward-purple.svg') no-repeat !important;
    transform: scale(0.8) !important;
    position: absolute !important;
    top: 15px !important;
    right: 10px !important;
}

// Material 15+
.mat-mdc-menu-panel {
    max-width: unset !important;
    box-shadow: $malou-shadow-1 !important;
    background-color: $malou-color-white !important;
    border-radius: toRem(10px) !important;

    .mat-mdc-menu-item:hover {
        background-color: $malou-color-background-dark !important;
    }
}

.malou-mat-menu .mat-mdc-menu-content .mat-mdc-menu-item {
    color: $malou-color-text-2;

    .mat-mdc-menu-submenu-icon {
        height: 18px !important;
        width: 18px !important;
        right: 10px !important;
        background: url('/apps/app-malou-web/src/assets/icons/arrow-forward-purple.svg') no-repeat;
        polygon {
            display: none;
        }
    }
}
