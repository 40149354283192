/****************************************************************
 *
 * CSS Percentage Circle
 * Author: Andre Firchow
 *
*****************************************************************/

@use 'sass:math';

// Compass utilities
@use '_malou_variables' as *;
@import 'compass-mixins/lib/compass/css3/transform';
@import 'compass-mixins/lib/compass/css3/box-sizing';
@import 'compass-mixins/lib/compass/css3/transition';

// VARS
$circle-width: 0.08em;
$circle-width-hover: 0.04em;

// colors default
$primary-color: $malou-color-leslie;
$secondary-color: #ccc;
$bg-color: $malou-color-white;

$primary-color-green: #4db53c;
$primary-color-orange: #dd9d22;

// colors dark skin
$primary-color-dark: #c6ff00;
$secondary-color-dark: #777;
$bg-color-dark: #666;

$primary-color-green-dark: #5fd400;
$primary-color-orange-dark: #e08833;

// CIRCLE
// classes 2 extend
.rect-auto {
    clip: rect(auto, auto, auto, auto);
}

.pie {
    position: absolute;
    border: $circle-width solid $primary-color;
    width: 1 - (2 * $circle-width);
    height: 1 - (2 * $circle-width);
    clip: rect(0em, 0.5em, 1em, 0em);
    border-radius: 50%;
    @include rotate(0deg);
}

.pie-fill {
    @include rotate(180deg);
}

// main
.c100 {
    *,
    *:before,
    *:after {
        @include box-sizing(content-box);
    }

    position: relative;
    font-size: 120px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    float: left;
    margin: 0 0.1em 0.1em 0;
    background-color: $secondary-color;

    // center circle to its parent
    &.center {
        float: none;
        margin: 0 auto;
    }

    // bigger size
    &.big {
        font-size: 240px;
    }

    // smaller size
    &.small {
        font-size: 46px;
    }

    // centered value inside circle
    > span {
        position: absolute;
        width: 100%;
        z-index: 1;
        left: 0;
        top: 0;
        width: 5em;
        line-height: 5em;
        font-size: 0.2em;
        color: $malou-color-leslie;
        display: block;
        text-align: center;
        white-space: nowrap;
        @include transition-property(all);
        @include transition-duration(0.2s);
        @include transition-timing-function(ease-out);
    }

    // background inside the circle
    &:after {
        position: absolute;
        top: $circle-width;
        left: $circle-width;
        display: block;
        content: ' ';
        border-radius: 50%;
        background-color: $bg-color;
        width: 1 - (2 * $circle-width);
        height: 1 - (2 * $circle-width);
        @include transition-property(all);
        @include transition-duration(0.2s);
        @include transition-timing-function(ease-in);
    }

    // the slice (mask)
    .slice {
        position: absolute;
        width: 1em;
        height: 1em;
        clip: rect(0em, 1em, 1em, 0.5em);
    }

    // circle to show the status
    .bar {
        @extend .pie;
    }

    // loop to create all needed elements automatically
    @for $j from 51 through 100 {
        &.p#{$j} .slice {
            @extend .rect-auto;
        }

        &.p#{$j} .bar:after {
            @extend .pie-fill;
        }

        &.p#{$j} .fill {
            @extend .pie;
            @extend .pie-fill;
        }
    }

    // loop to rotate all 100 circles
    @for $j from 1 through 100 {
        &.p#{$j} .bar {
            @include rotate(math.div(360, 100) * $j + deg);
        }
    }

    // hover styles
    // &:hover{

    // 	cursor: default;

    // 	> span {
    // 		width: 3.33em;
    // 		line-height: 3.33em;
    // 		font-size: 0.3em;
    // 		color: $primary-color;
    // 	}

    // 	&:after{
    // 		top: $circle-width-hover;
    // 		left: $circle-width-hover;
    // 		width: 1 - (2 * $circle-width-hover);
    // 		height: 1 - (2 * $circle-width-hover);
    // 	}

    // }

    // override colors for the dark skin
    &.dark {
        background-color: $secondary-color-dark;

        .bar,
        .fill {
            border-color: $primary-color-dark !important;
        }

        > span {
            color: $secondary-color-dark;
        }

        &:after {
            background-color: $bg-color-dark;
        }

        &:hover {
            > span {
                color: $primary-color-dark;
            }
        }
    }

    // green skin
    &.green {
        .bar,
        .fill {
            border-color: $primary-color-green !important;
        }

        &:hover {
            > span {
                color: $primary-color-green;
            }
        }
    }

    &.green.dark {
        .bar,
        .fill {
            border-color: $primary-color-green-dark !important;
        }

        &:hover {
            > span {
                color: $primary-color-green-dark;
            }
        }
    }

    // orange skin
    &.orange {
        .bar,
        .fill {
            border-color: $primary-color-orange !important;
        }

        &:hover {
            > span {
                color: $primary-color-orange;
            }
        }
    }

    &.orange.dark {
        .bar,
        .fill {
            border-color: $primary-color-orange-dark !important;
        }

        &:hover {
            > span {
                color: $primary-color-orange-dark;
            }
        }
    }
}
