@use '_malou_variables' as *;

.mdc-checkbox {
    width: 16px;
    height: 16px;
    --mdc-checkbox-selected-focus-state-layer-opacity: 1;
}

.mat-mdc-checkbox {
    margin-left: 1px;
    --mdc-checkbox-state-layer-size: 16px;
    --mdc-checkbox-unselected-focus-state-layer-opacity: 1;
    --mat-checkbox-label-text-tracking: normal;

    .mat-mdc-checkbox-touch-target {
        height: var(--mdc-checkbox-state-layer-size);
        width: var(--mdc-checkbox-state-layer-size);
    }

    &.mat-primary {
        --mdc-checkbox-disabled-unselected-icon-color: #{rgba($color: $malou-color-border-secondary, $alpha: 0.5)};
        --mdc-checkbox-disabled-selected-icon-color: #{rgba($color: $malou-color-primary, $alpha: 0.5)};

        --mdc-checkbox-unselected-icon-color: #{$malou-color-border-secondary};
        --mdc-checkbox-unselected-hover-icon-color: #{$malou-color-border-secondary};
        --mdc-checkbox-unselected-focus-icon-color: #{$malou-color-border-secondary};
        --mdc-checkbox-unselected-pressed-icon-color: #{$malou-color-border-secondary};

        --mdc-checkbox-selected-icon-color: #{$malou-color-primary};
        --mdc-checkbox-selected-focus-icon-color: #{$malou-color-primary};
        --mdc-checkbox-selected-hover-icon-color: #{$malou-color-primary};
        --mdc-checkbox-selected-pressed-icon-color: #{$malou-color-primary};

        --mdc-checkbox-selected-checkmark-color: #{$malou-color-white};

        .mdc-checkbox__background {
            border-width: 2px;
            border-radius: 3px;
            height: 16px;
            width: 16px;
            background-color: $malou-color-background-white !important;

            .mdc-checkbox__checkmark {
                width: 80% !important;
                position: static !important;
            }
        }
    }

    &.mat-mdc-checkbox-checked .mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
        background-color: var(--mdc-checkbox-selected-icon-color) !important;
    }

    .mdc-checkbox__ripple,
    .mat-mdc-checkbox-ripple {
        // Remove ripple
        --mdc-checkbox-unselected-hover-state-layer-opacity: 0;
        display: none;
    }

    .mdc-form-field {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
    }
}

.mdc-checkbox__mixedmark {
    margin-left: -9px;
    width: 80% !important;
}

.mdc-checkbox__checkmark-path {
    stroke-width: 5px !important;
}

.mdc-checkbox--disabled {
    cursor: not-allowed !important;
}
