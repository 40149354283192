@use '_malou_variables' as *;
@use '_malou_functions' as *;

.malou-mat-slider {
    padding: 0 !important;

    --mdc-slider-label-container-color: theme(colors.malou-color-primary);
    --mat-slider-value-indicator-height: 30px;
    --mat-slider-value-indicator-opacity: 1;
    --mat-slider-value-indicator-padding: 0 8px;

    &--no-thumb {
        height: fit-content !important;

        mat-slider-visual-thumb {
            padding: 0 !important;
            height: fit-content !important;
            top: 0 !important;
        }
    }
}
