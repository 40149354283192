@use '_malou_variables' as *;
@use '_malou_boxshadows' as *;
@use '_malou-typography' as *;

.mat-calendar-table-header-divider {
    --mat-datepicker-calendar-header-divider-color: #{$malou-color-border-primary};
}

.mat-calendar-body-in-range::before {
    background: $malou-color-background-dark !important;
}

.mat-calendar-body-selected {
    font-weight: $malou-font-weight-medium;
    background: $malou-color-gradient-btn;
    border: none !important;
}

.mat-calendar-body-label {
    color: white !important;
}

.mat-calendar-body-label[colspan='7'] {
    display: none;
}

.mat-datepicker-content {
    @apply rounded-xl;
    @extend .malou-box-shadow;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background: $malou-color-background-dark !important;
    border: none !important;
}

.mat-calendar-body-disabled
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: rgba($malou-color-text-1, 0.5) !important;
}

.mat-calendar-table-header-divider {
    &::after {
        margin: 0 20px !important;
    }
}

.mat-calendar-table-header-divider::after {
    background-color: $malou-color-background-dark;
}

.mat-calendar-table-header th {
    @apply malou-text-11--regular;
}

.mat-datepicker-toggle .mat-mdc-button-base {
    line-height: 1;
}

.mat-calendar-body-cell-content {
    @apply malou-text-13--regular;
}

.mat-icon.small-icon {
    height: 1rem;
    width: 1rem;
    --mdc-icon-button-icon-size: 1rem;
}
