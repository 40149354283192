@use '_malou_variables' as var;

// Font Weight

@each $weight in map-keys(var.$font-weights) {
    .malou-text-weight-#{$weight} {
        font-weight: map-get(var.$font-weights, $weight);
    }
}

// Text Transform

.malou-text-transform-uppercase {
    text-transform: uppercase;
}

.malou-text-transform-lowercase {
    text-transform: lowercase;
}

// Line Height

.malou-text-height-18 {
    line-height: 18px;
}

// Font Size

@each $size in var.$font-sizes {
    .malou-text-#{$size} {
        font-size: #{$size}px;

        @each $weight in map-keys(var.$font-weights) {
            &--#{$weight} {
                font-size: #{$size}px;
                font-weight: map-get(var.$font-weights, $weight);
            }
        }
    }
}

// Other

.malou-text-heading {
    font-size: 44px;
    font-weight: 600;
    color: var.$malou-color-text-1;
}

.malou-text-title {
    font-size: 32px;
    font-weight: 700;
}

.malou-text-section-title {
    font-size: 18px;
    font-weight: 700;
}

.malou-text__card-title {
    font-size: 18px !important;
    font-weight: var.$malou-font-weight-extrabold;
}

.malou-text__card-subtitle {
    font-size: 10px !important;
    font-style: italic;
    color: var.$malou-color-text-2;
}

@import url('https://fonts.cdnfonts.com/css/cosmopolitan-script');
@import url('https://fonts.cdnfonts.com/css/proxima-nova-condensed');
@import url('https://fonts.googleapis.com/css2?family=Dosis&family=Roboto:ital,wght@0,400;0,700;0,900;1,900&display=swap');
