@use '_malou_variables' as *;
@use '_malou_functions.scss' as *;

$slide-toggle-disabled-opacity: 0.5;

.mat-mdc-slide-toggle {
    --mdc-switch-disabled-selected-icon-opacity: #{$slide-toggle-disabled-opacity};
    --mdc-switch-disabled-unselected-icon-opacity: #{$slide-toggle-disabled-opacity};

    .mdc-switch--disabled .mdc-switch__handle-track {
        cursor: not-allowed;
    }

    .mdc-switch__handle {
        $handle-size: 16px;
        --mat-switch-with-icon-handle-size: #{$handle-size};
        --mat-switch-pressed-handle-size: #{$handle-size};
    }

    .mdc-switch__handle-track {
        $handle-track-margin: 4px;
        $handle-track-color: white;

        margin-left: $handle-track-margin;
        width: calc(100% - var(--mdc-switch-handle-width) - (2 * $handle-track-margin)) !important;

        --mdc-switch-handle-height: 16px;
        --mdc-switch-handle-shape: calc(var(--mdc-switch-handle-height));
        --mdc-switch-handle-width: var(--mdc-switch-handle-height);

        --mdc-switch-disabled-handle-opacity: #{$slide-toggle-disabled-opacity};

        --mdc-switch-handle-surface-color: var(--mdc-theme-surface, $handle-track-color);

        --mdc-switch-handle-shadow-color: #{$handle-track-color};
        --mdc-switch-handle-elevation-shadow: none;
        --mdc-switch-disabled-handle-elevation-shadow: none;

        --mdc-switch-selected-handle-color: #{$handle-track-color};
        --mdc-switch-selected-focus-handle-color: #{$handle-track-color};
        --mdc-switch-selected-hover-handle-color: #{$handle-track-color};
        --mdc-switch-selected-pressed-handle-color: #{$handle-track-color};
        --mdc-switch-disabled-selected-handle-color: #{$handle-track-color};

        --mdc-switch-unselected-handle-color: #{$handle-track-color};
        --mdc-switch-unselected-focus-handle-color: #{$handle-track-color};
        --mdc-switch-unselected-hover-handle-color: #{$handle-track-color};
        --mdc-switch-unselected-pressed-handle-color: #{$handle-track-color};
        --mdc-switch-disabled-unselected-handle-color: #{$handle-track-color};
    }

    .mdc-switch__track {
        $selected-switch-track-color: theme('colors.malou-color-primary');
        $unselected-switch-track-color: theme('colors.malou-color-text-2/0.2');

        --mdc-switch-track-height: 22px;
        --mdc-switch-track-shape: calc(var(--mdc-switch-track-height) / 2);
        --mdc-switch-track-width: 38px;

        --mdc-switch-disabled-track-opacity: #{$slide-toggle-disabled-opacity};

        --mdc-switch-selected-track-color: #{$selected-switch-track-color};
        --mdc-switch-selected-focus-track-color: #{$selected-switch-track-color};
        --mdc-switch-selected-hover-track-color: #{$selected-switch-track-color};
        --mdc-switch-selected-pressed-track-color: #{$selected-switch-track-color};
        --mdc-switch-disabled-selected-track-color: #{$selected-switch-track-color};

        --mdc-switch-unselected-track-color: #{$unselected-switch-track-color};
        --mdc-switch-unselected-focus-track-color: #{$unselected-switch-track-color};
        --mdc-switch-unselected-hover-track-color: #{$unselected-switch-track-color};
        --mdc-switch-disabled-unselected-track-color: #{$unselected-switch-track-color};
        --mdc-switch-unselected-pressed-track-color: #{$unselected-switch-track-color};
    }

    .mdc-switch__icon {
        $icon-size: 0px;
        $icon-color: white;

        --mdc-switch-selected-icon-size: #{$icon-size};
        --mdc-switch-unselected-icon-size: #{$icon-size};

        --mdc-switch-selected-icon-color: #{$icon-color};
        --mdc-switch-disabled-selected-icon-color: #{$icon-color};
        --mdc-switch-disabled-unselected-icon-color: #{$icon-color};
        --mdc-switch-unselected-icon-color: #{$icon-color};
    }
}
