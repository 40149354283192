@use '_malou_menus' as *;
@use '_malou_tooltips' as *;
@use 'malou_accordion' as *;
@use 'malou_animations' as *;
@use 'malou_avatars' as *;
@use 'malou_backdrops' as *;
@use 'malou_borders' as *;
@use 'malou_boxshadows' as *;
@use 'malou_buttons' as *;
@use 'malou_card' as *;
@use 'malou_checkbox' as *;
@use 'malou_chips' as *;
@use 'malou_colors' as *;
@use 'malou_components' as *;
@use 'malou_date-picker' as *;
@use 'malou_dialog' as *;
@use 'malou_fonts' as *;
@use 'malou_lists' as *;
@use 'malou_logo' as *;
@use 'malou_mixins' as *;
@use 'malou_overflow' as *;
@use 'malou_paginator' as *;
@use 'malou_posts.scss' as *;
@use 'malou_radios' as *;
@use 'malou_scrollbar' as *;
@use 'malou_select' as *;
@use 'malou_slide_toggles' as *;
@use 'malou_slider' as *;
@use 'malou_snackbar' as *;
@use 'malou_statuses' as *;
@use 'malou_stepper' as *;
@use 'malou_table' as *;
@use 'malou_tabs' as *;
@use 'malou_variables' as *;
@use 'malou-flex' as *;
@use 'malou-form' as *;
@use 'malou-typography' as *;
@use 'percentage_circle.scss' as *;

.malou-spinner__container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.malou-platform-logo {
    // use on img tag
    border-radius: 50%;
    margin-right: 5px;
    align-self: center;
}

.malou-shadow {
    @include malou-shadow;
}

.malou-break-word {
    overflow-wrap: break-word;
    word-break: break-word;
}
