@import '../malou/malou_variables';

.dts-no-select {
    user-select: none;
}

.dts-select-box {
    background: rgba($malou-color-purple--light, 0.2);
    border: 2px solid $malou-color-purple--light;
    contain: layout;
    transform: translateZ(0);
    box-sizing: border-box;
    color: #7ddafc;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    pointer-events: none;
    opacity: 0;
    z-index: 1;
}

.dts-select-box.dts-removing {
    background: rgba($malou-color-purple--light, 0.2);
    border: 2px solid $malou-color-purple--light;
}

.dts-select-container:not(.dts-custom) .dts-select-item.selected {
    border: 1px solid $malou-color-purple--light;
}

.dts-select-container:not(.dts-custom) .dts-select-item.dts-range-start {
    border: 1px solid $malou-color-purple--light;
}
