// --------------------------------------------
// Old Variables
// --------------------------------------------
$malou-border-radius: 4px; // border radius
$malou-card-header-padding: 10px 15px;
$malou-card-padding: 15px; // malou card padding
$malou-card-footer-padding: 5px 15px;
$malou-card-margin-top: 15px;
$malou-card-margin-bottom: 15px;
$malou-card-margin-side: 10px;
$malou-margin-default: 10px; // default margin value
$malou-container-margin: 15px;
$malou-card__header-height: 30px;
$malou-navbar-height: 61px;
$malou-footer-height-small-screen: 155px;
$malou-footer-height: 60px;

// colors (add one class to set color, one to set background color for each variable)
$malou-color-white: white;
$malou-color-black: black;
$malou-color-primary-blue: #1d2250;
$malou-color-leslie-light: #4c6380;
$malou-color-leslie: #192a3e;
$malou-color-leslie-subtitle: #90a0b7;
$malou-color-carolina-ultra-light: #d8e9f3;
$malou-color-carolina-light: #9fd3f3;
$malou-color-carolina: #109cf1;
$malou-color-text: rgba(
    $color: $malou-color-black,
    $alpha: 0.87,
);
$malou-color-dark: #323c47;
$malou-color-dark-lighter: rgba(
    $color: #323c47,
    $alpha: 0.6,
);
$malou-color-border-dark: rgba(
    $color: $malou-color-black,
    $alpha: 0.12,
);
$malou-color-shadow: rgba(
    $color: $malou-color-black,
    $alpha: 0.05,
);
$malou-color-row-hover: rgba(
    $color: #5f676b,
    $alpha: 0.01,
);
$malou-color-border-light: #ebeff2;
$malou-color-primary: #3f51b5;
$malou-color-warning: #face55;
$malou-color-success: #2ed47a;
$malou-color-fail: #cd2900;
$malou-color-warning-button: #eb5757;
$malou-color-grey-light: #c2cfe0;
$malou-color-blue-dark: #334d6e;
$malou-color-background: #f5f6f8;
$malou-color-background-grey: #c4c4c4;
$malou-color-background-pale: #fafafa;
$malou-color-highlight-main: #f2f9ff;
$malou-color-pending: #ffb946;
$malou-color-sort-arrow: #757575;
$malou-color-stepper-bar: #97989b;
$malou-color-warning-light: #f8dbac;
$malou-color-notification: #f7685b;
$malou-color-facebook: #0084ff;
$malou-color-facebook-dark: #425797;

$malou-scrollbar-color: #dbd1ff;

// NEW VARS
// -----------------------------------------------
/*
    SECTION 1 - COLORS
*/
// -----------------------------------------------------------------
// Primary Colors

$malou-color-primary: #6a52fd;
$malou-color-primary--hover: #604ce4;
$malou-color-primary--disabled: #b5a8fe;
$malou-color-primary--light: rgba($malou-color-primary, 0.3);

// Charts colors
$malou-color-chart-purple: #8247e4;
$malou-color-purple--light: #c4b4feff;
$malou-color-chart-purple--accent: #ac32b7;
$malou-color-chart-pink: #d71d88;
$malou-color-chart-pink--accent: #ee116e;
$malou-color-chart-pink--light: #ffbbc7;
$malou-color-chart-green: #9aeaba;

// -----------------------------------------------------------------
// Texts Colors

$malou-color-text-1: #0a2540;
$malou-color-text-2: #4a5e73;
$malou-color-text-2--light: rgba($malou-color-text-2, 0.5);
$malou-color-text-2--lighter: rgba($malou-color-text-2, 0.2);
$malou-color-text-white: #ffffff;
$malou-color-text-green: #34a762;
$malou-color-text-pink--light: #ff8181;
$malou-color-text-purple--light: #987cfb;

// -----------------------------------------------------------------
// Backgrounds Colors

$malou-color-background-white: #ffffff;
$malou-color-background-light: #f9faff;
$malou-color-background-dark: #f2f2ff;
$malou-color-background-purple: #f1eeff;
$malou-color-background-warning: #fff1dc;
$malou-color-background-linear: linear-gradient(180deg, $malou-color-background-light 0%, #f9faff00 100%);
$malou-color-background-loader: #e8e5ee;
$malou-color-background-error: #f8e3f0;
$malou-color-background-purple-accent-light: rgba($malou-color-chart-purple--accent, 0.1);
$malou-color-background-purple-light: rgba($malou-color-purple--light, 0.2);

// -----------------------------------------------------------------
// State Colors
$malou-color-state-success: #34b467;
$malou-color-state-success--light: #ccedd9;
$malou-color-state-success--lighter: #cfebde;
$malou-color-state-error: #ee116e;
$malou-color-state-error--light: #f788b7;
$malou-color-bg-state-error: #fff1f4;

$malou-color-state-warn: #ffba4c;

$malou-color-state-warn--light: #fff1db;

// -----------------------------------------------------------------
// Gradient Colors

$malou-color-gradient-primary: linear-gradient(
    45deg,
    $malou-color-primary 0%,
    $malou-color-chart-purple--accent 50%,
    $malou-color-chart-pink 100%
);
$malou-color-gradient-2: linear-gradient(45deg, $malou-color-primary 0%, $malou-color-chart-purple 100%);
$malou-color-gradient-3: linear-gradient(45deg, $malou-color-chart-purple 0%, $malou-color-chart-purple--accent 100%);
$malou-color-gradient-4: linear-gradient(45deg, $malou-color-chart-purple 0%, $malou-color-chart-pink 100%);
$malou-color-gradient-5: linear-gradient(45deg, $malou-color-primary 0%, $malou-color-chart-purple--accent 100%);
$malou-color-gradient-btn: linear-gradient(45deg, $malou-color-primary 0%, $malou-color-chart-purple--accent 100%);
$malou-color-gradient-btn--hover: linear-gradient(45deg, $malou-color-chart-purple--accent 0%, $malou-color-primary 100%);
$malou-color-gradient-background--hover: linear-gradient(
    360deg,
    rgba(
            $color: $malou-color-background-dark,
            $alpha: 0.32,
        )
        0.52%,
    $malou-color-background-dark 41.67%,
    rgba(
            $color: $malou-color-background-dark,
            $alpha: 0,
        )
        100%
);
$malou-color-gradient-success: linear-gradient(45deg, $malou-color-state-success 0%, $malou-color-state-success--lighter 100%);

// -----------------------------------------------------------------
// Border Colors
$malou-color-border-primary: #f2f2ff;
$malou-color-border-secondary: #c4b4fe;

// -----------------------------------------------------------------

// -----------------------------------------------------------------
// Backdrop color
$malou-color-backdrop: rgba(10, 37, 64, 0.2);

// -----------------------------------------------------------------

// Chips colors
$malou-chip-background-primary: #e9e5ff;
$malou-chip-background-select: rgba($malou-color-primary, 0.5);
$malou-chip-text-primary: #4a5e73;
$malou-chip-text-error--light: #fccfe2;

// -----------------------------------------------------------------

// Other colors
$malou-color-transparent: transparent;

// -----------------------------------------------------------------
/*
    SECTION 2 - FONTS
*/
$malou-font-family: 'Poppins', sans-serif;

$malou-font-weight-regular: 400;
$malou-font-weight-medium: 500;
$malou-font-weight-semibold: 600;
$malou-font-weight-bold: 700;
$malou-font-weight-extrabold: 800;

$font-weights: (
    regular: $malou-font-weight-regular,
    medium: $malou-font-weight-medium,
    semibold: $malou-font-weight-semibold,
    bold: $malou-font-weight-bold,
    extrabold: $malou-font-weight-extrabold,
);

$font-sizes: 3, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 21, 22, 23, 24, 30, 32, 34, 35, 40, 50, 100;

// -----------------------------------------------------------------
/*
    SECTION 3 - Shadows
*/
$malou-shadow-1: 0px 4px 14px rgba($malou-color-primary, 0.2);
$malou-shadow-1--more-opaque: 0px 4px 14px rgba($malou-color-primary, 0.3);

// -----------------------------------------------------------------
/*
    SECTION 4 - Dimensions
*/
$malou-sidenav-width: 80px;
$malou-sidenav-opened-width: 250px;
$malou-headernav-height: 60px;

$malou-gallery-navigation-header-height: 64px;
$malou-gallery-filters-height: 92px;
$malou-seo-posts-filters-height: 110px;
$malou-seo-posts-navigation-header-height: 64px;

$malou-review-navigation-header-height: 64px;
$malou-review-navigation-header-margin: 0.875rem;
$malou-review-filters-height: 52px;
$malou-review-filters-margin: 1.5rem;

$malou-statistics-navigation-header-height: 48px;

$malou-settings-navigation-header-height: 48px;
$malou-settings-navigation-header-padding: 14px;
$malou-roles-manager-filter-height: 52px;
$malou-roles-manager-filter-margin: 1.5rem;
$malou-roles-manager-table-header-height: 56px;

$malou-social-navigation-header-height: 62px;
$malou-social-navigation-header-margin: 20px;
$malou-social-filters-height: 66px;

$malou-headernav-height__mobile: 80px;
$malou-gallery-filters-height__mobile: 150px;
$malou-review-filters-height__mobile: 150px;
$malou-statistics-filters-height__mobile: 118px;
$malou-settings-navigation-header-height__mobile: 175px;
$malou-social-navigation-header-height__mobile: 78px;
$malou-social-filters-height__mobile: 105px;

$malou-calendar-nav: 140px;
$malou-calendar-table-margin: 48px;

// -----------------------------------------------------------------
// Material variables
// -----------------------------------------------------------------
$malou-breakpoints: (
    small: 639px,
    medium: 767px,
    large: 1023px,
    extra-large: 1279px,
);

$map-primary: (
    400: $malou-color-primary,
    700: $malou-color-primary--hover,
    contrast: (
        400: $malou-color-white,
        700: $malou-color-white,
    ),
);

$map-accent: (
    400: $malou-color-chart-pink,
    700: $malou-color-chart-pink,
    contrast: (
        400: $malou-color-white,
        700: $malou-color-white,
    ),
);

$map-warn: (
    400: $malou-color-state-error,
    700: $malou-color-state-error,
    contrast: (
        400: $malou-color-white,
        700: $malou-color-white,
    ),
);

// -----------------------------------------------------------------
// Common variables
// -----------------------------------------------------------------
$mediaAndFoldersGridTemplateColumnsSize: 240px;
