@use '_malou_variables' as *;
@use 'malou_boxshadows' as *;

.malou-snackbar-warning {
    background-color: orange;
}

.mat-mdc-snack-bar-container {
    --mdc-snackbar-supporting-text-font: #{$malou-font-family};
    --mdc-snackbar-container-color: #{$malou-color-background-light};
    --mdc-snackbar-container-shape: 10px !important;

    .mdc-snackbar__surface {
        @extend .malou-box-shadow;
    }
}
