@use '_malou_functions' as *;
@use '_malou_variables' as *;

// OLD CSS COLORS CLASSES
//----------------------------------------------------------------
.malou-color-white {
    color: $malou-color-white !important;
}

.malou-color-bg-white {
    background-color: $malou-color-white;
}

.malou-color-black {
    color: $malou-color-black;
}

.malou-color-bg-black {
    background-color: $malou-color-black;
    color: $malou-color-white;
}

.malou-color-primary-blue {
    color: $malou-color-primary-blue;
}

.malou-color-bg-primary-blue {
    color: $malou-color-white;
    background-color: $malou-color-primary-blue;
}

.malou-color-leslie-light {
    color: $malou-color-leslie-light;
}

.malou-color-bg-leslie-light {
    background-color: $malou-color-leslie-light;
    color: $malou-color-white;
}

.malou-color-leslie-subtitle {
    color: $malou-color-leslie-subtitle;
}

.malou-color-bg-leslie-subtitle {
    background-color: $malou-color-leslie-subtitle;
    color: $malou-color-white;
}

.malou-color-carolina {
    color: $malou-color-carolina;
}

.malou-color-bg-carolina {
    background-color: $malou-color-carolina;
    color: $malou-color-white;
}

.malou-color-leslie {
    color: $malou-color-leslie;
}

.malou-color-bg-leslie {
    background-color: $malou-color-leslie;
    color: $malou-color-white;
}

.malou-color-dark-lighter {
    color: $malou-color-dark-lighter;
}

.malou-color-dark {
    color: $malou-color-dark;
}

.malou-color-bg-dark {
    background-color: $malou-color-dark !important;
    color: $malou-color-white !important;
}

.malou-color-shadow {
    color: $malou-color-shadow;
}

.malou-color-bg-shadow {
    background-color: $malou-color-shadow;
    color: $malou-color-white;
}

.malou-color-border-dark {
    color: $malou-color-border-dark;
}

.malou-color-bg-border-dark {
    background-color: $malou-color-border-dark;
    color: $malou-color-white;
}

.malou-color-border-light {
    color: $malou-color-border-light !important;
}

.malou-color-bg-border-light {
    background-color: $malou-color-border-light;
    color: $malou-color-white;
}

.malou-color-primary {
    color: $malou-color-primary;
}

.malou-color-bg-primary {
    background-color: $malou-color-primary;
}

.malou-color-bg-primary--light {
    background-color: $malou-color-primary--light;
}

.malou-color-bg-warning {
    background-color: $malou-color-warning;
    color: $malou-color-white;
}

.malou-color-warning {
    color: $malou-color-warning;
}

.malou-color-bg-success {
    background-color: $malou-color-success;
}

.malou-color-bg-success-lighter {
    background-color: $malou-color-state-success--lighter;
}

.malou-color-bg-text-2--lighter {
    background-color: $malou-color-text-2--lighter;
}

.malou-color-success {
    color: $malou-color-success;
}

.malou-color-bg-fail {
    background-color: $malou-color-fail;
    color: $malou-color-white;
}

.malou-color-fail {
    color: $malou-color-fail;
}

.malou-color-bg-grey-light {
    background-color: $malou-color-grey-light;
    color: $malou-color-white;
}

.malou-color-grey-light {
    color: $malou-color-grey-light;
}

.malou-color-bg-blue-dark {
    background-color: $malou-color-blue-dark !important;
    color: $malou-color-white !important;
}

.malou-color-blue-dark {
    color: $malou-color-blue-dark;
}

.malou-color-bg-warning-button {
    background-color: $malou-color-warning-button;
    color: $malou-color-white;
}

.malou-color-warning-button {
    color: $malou-color-warning-button;
}

.malou-color-carolina-light {
    color: $malou-color-carolina-light;
}

.malou-color-bg-carolina-light {
    background-color: $malou-color-carolina-light;
    color: $malou-color-white;
}

.malou-color-bg-warn {
    background-color: $malou-color-state-warn;
}

// DEPRECATED
.malou-color-text {
    color: $malou-color-text;
    --deprecated: 'WARNING: No longer a color of the design system, use malou-color-text-1 or malou-color-text-2 instead';
}

.malou-color-state-success {
    color: $malou-color-state-success;
}

.malou-color-state-success--light {
    color: $malou-color-state-success--light;
}

.malou-color-state-error {
    color: $malou-color-state-error !important;
}

.malou-color-state-warn {
    color: $malou-color-state-warn;
}

.malou-bg-state-success {
    background-color: $malou-color-state-success;
}

.malou-bg-state-success--light {
    background-color: $malou-color-state-success--light;
}

.malou-bg-state-error {
    background-color: $malou-color-state-error;
}

.malou-bg-error {
    background-color: $malou-color-background-error;
}

.malou-bg-purple-accent-light {
    background-color: $malou-color-background-purple-accent-light;
}

.malou-bg-state-warn {
    background-color: $malou-color-state-warn;
}

.malou-bg-state-warn--light {
    background-color: $malou-color-state-warn--light;
}

.malou-color-bg-text {
    background-color: $malou-color-text;
    color: $malou-color-white;
}

.malou-color-background {
    color: $malou-color-background;
}

.malou-color-bg-background {
    background-color: $malou-color-background;
    color: $malou-color-white;
}

.malou-color-background-grey {
    color: $malou-color-background-grey;
}

.malou-color-bg-background-grey {
    background-color: $malou-color-background-grey;
    color: $malou-color-white;
}

.malou-color-bg-background-pale {
    background-color: $malou-color-background-pale;
    color: $malou-color-white;
}

.malou-color-row-hover {
    color: $malou-color-row-hover;
}

.malou-color-bg-row-hover {
    background-color: $malou-color-row-hover;
    color: $malou-color-white;
}

.malou-color-highlight-main {
    color: $malou-color-highlight-main;
}

.malou-color-bg-highlight-main {
    background-color: $malou-color-highlight-main;
}

.malou-color-pending {
    color: $malou-color-pending;
}

.malou-color-bg-alert {
    background-color: $malou-color-warning-light;
    color: $malou-color-black;
}

.malou-color-facebook {
    color: $malou-color-facebook;
}

.malou-color-notification {
    color: $malou-color-notification;
}

.malou-color-purple-light {
    color: $malou-color-chart-purple;
    opacity: 0.3;
}

.malou-color-purple {
    color: $malou-color-chart-purple;
}

// END OLD CSS COLORS CLASSES
//----------------------------------------------------------------

// NEW CSS COLORS CLASSES
//----------------------------------------------------------------

.malou-color-backdrop {
    background-color: $malou-color-backdrop;
}

.malou-color-background-light {
    background-color: $malou-color-background-light;
}

.malou-color-background-dark {
    background-color: $malou-color-background-dark;
}

.malou-color-background-purple-light {
    background-color: $malou-color-background-purple-light;
}

.malou-color-background-purple {
    background-color: $malou-color-background-purple;
}

.malou-color-text-1 {
    color: $malou-color-text-1 !important;
}

.malou-color-text-2 {
    color: $malou-color-text-2 !important;
}

.malou-color-text-primary {
    color: $malou-color-primary;
}

.malou-color-text-2-light {
    color: $malou-color-text-2;
    opacity: 0.3;
}

.malou-background-black {
    background: $malou-color-black;
}

.malou-background-white {
    background: $malou-color-white;
}

.malou-background-light {
    background: $malou-color-background-light;
}

.malou-background-light-important {
    background: $malou-color-background-light !important;
}

.malou-background-gradient-5 {
    background: $malou-color-gradient-5;
}

.malou-color-chart-pink--accent {
    color: $malou-color-chart-pink--accent;
}

.malou-color-background-chart-pink--accent {
    background-color: $malou-color-chart-pink--accent;
}

.malou-color-background--warning {
    background-color: $malou-color-background-warning;
}

// Material colors
// ----------------
.mat-gradient {
    background: $malou-color-gradient-primary;
}

.malou-border-color-dark {
    border-color: $malou-color-border-primary;
    --mat-divider-color: #{$malou-color-border-primary};
}

.malou-border-color-success {
    border-color: $malou-color-state-success;
    --mat-divider-color: #{$malou-color-state-success};
}

.malou-border-color-gradient-success {
    border-color: $malou-color-background-light;
    background:
        linear-gradient($malou-color-white, $malou-color-white) padding-box,
        $malou-color-gradient-success border-box;
}

.malou-border-color-warn {
    border-color: $malou-color-state-warn;
    --mat-divider-color: #{$malou-color-state-warn};
}

.malou-border-color-error {
    border-color: $malou-color-state-error;
    --mat-divider-color: #{$malou-color-state-error};
}

.malou-color-border--warning {
    border-color: $malou-color-background-warning;
}

.malou-color-border--primary {
    border-color: $malou-color-primary;
}
