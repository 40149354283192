@use '_malou_variables' as *;

.malou-expansion-panel {
    .mat-accordion .mat-expansion-panel {
        border-radius: 13px !important;
    }

    .mat-expansion-panel {
        box-shadow: none;
        border: solid 1px $malou-color-border-primary;
        border-radius: 7px;
        margin-bottom: 8px;
        padding: 0;
        &:hover:not(.mat-expanded) {
            background-color: $malou-color-background-purple;
        }

        .mat-expansion-indicator {
            margin-right: 4px;
        }
    }

    .custom-expansion-panel-header:not(.mat-expanded) {
        &:hover {
            background-color: $malou-color-background-purple !important;
        }
    }

    .custom-expansion-panel-header--disabled {
        cursor: auto;
        &:hover:not(.mat-expanded) {
            background-color: white !important;
        }
    }

    .mat-expansion-panel-spacing {
        margin-top: 0;
    }

    .mat-expansion-panel:not([class*='mat-elevation-z']) {
        box-shadow: none;
    }

    .mat-expansion-panel-header {
        padding: 0 15px;
        height: 50px !important;
    }

    .mat-expanded-panel-header:not(.mat-expanded) {
        background-color: white !important;
    }

    .mat-expansion-panel-header .mat-expanded {
        border-bottom: 0px !important;
    }

    .mat-expansion-indicator::after {
        border-color: $malou-color-primary;
        width: 10px;
        height: 10px;
        margin-right: 4px;
    }

    .mat-expansion-panel-content .mat-expansion-panel-body {
        padding: 0;
    }

    thead {
        height: 50px;
        background-color: $malou-color-background-light;
    }
}
