@use '_malou_mixins' as *;
@use '_malou_variables' as *;
@use '_malou-typography' as *;

$transition-time: 0.15s;

.malou-card-container {
    margin: 0 auto;
    max-width: 900px;
}

.malou-card {
    display: flex;
    flex: 1 1 300px;
    flex-direction: column;
    position: relative;
    background-color: $malou-color-white;
    border-radius: 10px;
    margin: $malou-card-margin-top $malou-card-margin-side $malou-card-margin-bottom $malou-card-margin-side;
    padding: 26px;
    border: 1px solid $malou-color-background-dark;

    &.malou-hover:hover {
        transition: all $transition-time;
        @include malou-shadow-up;

        .malou-card__header,
        .malou-content-hover {
            transition: all $transition-time;
        }
    }

    .malou-card__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding-bottom: 26px;
        color: $malou-color-leslie;
        border-radius: $malou-border-radius;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            bottom: 0;
            margin: 0 auto;
        }
    }

    .malou-card__body--padding-2x {
        padding: 2 * $malou-card-padding;
    }

    .malou-card__image {
        align-self: center;
        max-width: 100%;
    }

    .malou-card__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        min-width: 32px;
    }

    .malou-card__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: $malou-card-footer-padding;
        color: $malou-color-leslie;

        &::before {
            border-bottom: 1px solid $malou-color-border-light;
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            top: 0;
            margin: 0 auto;
        }
    }

    .malou-card__footer mat-icon {
        vertical-align: middle;
    }

    .malou-card__footer li {
        display: flex;
    }

    .malou-card__seperator {
        position: relative;
        border-bottom: 1px solid $malou-color-border-light;
        left: -$malou-card-padding;
        right: -$malou-card-padding;
        width: calc(100% + 2 * #{$malou-card-padding});
        margin: $malou-card-margin-top 0 $malou-card-margin-bottom 0;
    }
}

.autosave-state-wrapper {
    display: flex;
    align-items: center;
    margin-left: 20px;

    span {
        margin-left: 10px;
    }

    mat-icon {
        font-size: 20px;
    }
}

.saved-state {
    color: $malou-color-success;

    mat-icon {
        color: $malou-color-success;
    }
}

.malou-single-card {
    flex-basis: 250px !important;
    flex-grow: 2 !important;
}

.malou-simple-card {
    background-color: $malou-color-background-white;
    border-radius: 10px;
    border: 1px solid $malou-color-border-primary;
}

.malou-simple-card--light {
    background-color: $malou-color-background-light;
    border-radius: 10px;
    border: 1px solid $malou-color-border-primary;
}

.malou-card__container-margin {
    margin-left: -10px;
    margin-right: -10px;
}

.malou-card--small {
    @extend .malou-card;
    max-width: 300px;
}

.malou-card--medium {
    @extend .malou-card;
    max-width: 600px;
}

.malou-card--large {
    @extend .malou-card;
    max-width: 900px;
}

.malou-card--secondary {
    @extend .malou-card;
    background-color: $malou-color-background-dark;

    &-alt {
        @extend .malou-card--secondary;
        background-color: $malou-color-background-light;
    }
    &.malou-card-active {
        border: 1px solid $malou-color-primary !important;
    }
    &.lighter {
        background-color: $malou-color-background-light;
    }
}

.malou-card__title {
    @extend .malou-text__card-title;
}

.malou-card__action-button {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.malou-card__image-container {
    width: 42px;
    height: 42px;
    margin-right: 14px;
}

.malou-card__body-text {
    @extend .malou-text-10--regular;
    @extend .malou-text-height-18;
    color: $malou-color-text-2;
}

.malou-card__vertical-separator {
    width: 2px;
    min-width: 2px;
    border-radius: 10px;
    background: $malou-color-gradient-2;
    margin: 0 10px;
}

.malou-card__media {
    cursor: pointer;
    width: 50px;
    height: 50px;
    max-width: none;

    img {
        object-fit: cover;
        width: 50px;
        height: 50px;
    }
}

.malou-card__image-suffix-overlay {
    width: 50px;
    height: 50px;
    position: relative;

    img,
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    span {
        z-index: 1;
        color: $malou-color-white;
        display: grid;
        place-items: center;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(0px);
        background-color: rgba($malou-color-text-1, 0.5);
        line-height: normal;
    }
}
