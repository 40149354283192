@use '_malou_functions' as *;

#brand-logo {
    width: toRem(100px);
    cursor: pointer;
}

.malou-logo {
    border-radius: 15%;
}

.malou-logo--small {
    @extend .malou-logo;
    width: toRem(15px);
    height: toRem(15px);
}

.malou-logo--medium {
    @extend .malou-logo;
    width: toRem(34px);
    height: toRem(34px);
}

.malou-logo--large {
    @extend .malou-logo;
    width: toRem(50px);
    height: toRem(50px);
}
