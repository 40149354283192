@use 'malou_variables' as var;

/* material shadow */
@mixin malou-shadow {
    box-shadow: 0px 6px 18px var.$malou-color-shadow;
}

@mixin malou-shadow-up {
    box-shadow: 0px 14px 28px rgba($color: var.$malou-color-black, $alpha: 0.25);
}

@mixin malou-shadow-medium {
    box-shadow: 0px 6px 18px rgba($color: var.$malou-color-shadow, $alpha: 0.12);
}

/// Mixin to manage responsive breakpoints
@mixin malou-respond-to($breakpoint) {
    // If the key exists in the map
    @if map-has-key(var.$malou-breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media (max-width: map-get(var.$malou-breakpoints, $breakpoint)) {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys(var.$malou-breakpoints)}.";
    }
}

// When making changes, also check `.hide-scrollbar` which does the same thing
// To use inside a media query, when you can't `@extend` classes
@mixin hide-scrollbar {
    // WebKit or Chromium based browsers
    &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
    }

    // Internet Explorer and Microsoft Edge, before Chromium
    -ms-oveflow-style: none;

    // Firefox
    scrollbar-width: none;
}
