@use '_malou_variables' as *;
@use '_malou_mixins' as *;

.stepper-header {
    &::before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: $malou-color-white;
        border-radius: 6px;
    }
    @include malou-respond-to('medium') {
        &::before {
            display: none;
        }
    }
}

.ink-bar {
    position: absolute;
    background: $malou-color-primary;
    bottom: 0;
    left: 0;
    width: 0px;
    height: 3px;
    border-radius: 6px;
    // @include malou-respond-to("medium") {
    //     display: none;
    // }
}
