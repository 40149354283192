@use '_malou_variables' as *;
@use '_malou_functions' as *;
@use '_malou-typography' as *;
@use '_malou_mixins' as *;

.malou-mat-table.mat-mdc-table {
    --mat-table-row-item-outline-width: 0;

    width: 100%;
    border-collapse: separate;
    border-spacing: 0 toRem(5px);
    background: unset;

    tr.mat-mdc-header-row {
        height: toRem(30px);
    }

    tr[role='row'].mat-mdc-header-row {
        th {
            @extend .malou-text-12--medium;
            border: none;
            color: $malou-color-text-2;
            background-color: $malou-color-background-linear;
        }
    }

    tr[role='row'].mat-mdc-row {
        background-color: $malou-color-background-white;
        cursor: pointer;
        th,
        td {
            border-top: toRem(1px) solid $malou-color-background-dark;
            border-bottom: toRem(1px) solid $malou-color-background-dark;
        }
        td.selected,
        th.selected {
            border-top: toRem(1px) solid $malou-color-border-secondary !important;
            border-bottom: toRem(1px) solid $malou-color-border-secondary !important;
        }
        th:first-child,
        td:first-child {
            border-top-left-radius: toRem(10px);
            border-bottom-left-radius: toRem(10px);
            border-left: toRem(1px) solid $malou-color-background-dark;
        }
        th.selected:first-child,
        td.selected:first-child {
            border-left: toRem(1px) solid $malou-color-border-secondary !important;
        }

        th:last-child,
        td:last-child {
            border-top-right-radius: toRem(10px);
            border-bottom-right-radius: toRem(10px);
            border-right: toRem(1px) solid $malou-color-background-dark;
        }
        th.selected:last-child,
        td.selected:last-child {
            border-right: toRem(1px) solid $malou-color-border-secondary !important;
        }

        &.selected {
            td,
            th {
                border-top: toRem(1px) solid $malou-color-border-secondary !important;
                border-bottom: toRem(1px) solid $malou-color-border-secondary !important;
            }

            th:first-child,
            td:first-child {
                border-left: toRem(1px) solid $malou-color-border-secondary !important;
            }
            th:last-child,
            td:last-child {
                border-right: toRem(1px) solid $malou-color-border-secondary !important;
            }
        }
    }

    mat-cell:first-of-type,
    mat-header-cell:first-of-type,
    mat-footer-cell:first-of-type {
        padding-left: toRem(24px);
    }

    mat-cell:last-of-type,
    mat-header-cell:last-of-type,
    mat-footer-cell:last-of-type {
        padding-right: toRem(24px);
    }

    .mat-sort-header-arrow {
        color: $malou-color-primary !important;
    }

    // <mat-table>
    .mat-mdc-header-row {
        height: toRem(30px);
        border: none;
        color: $malou-color-text-2;
        background-color: $malou-color-background-linear;
        @include malou-respond-to('small') {
            .mat-mdc-header-cell:not(:first-child) {
                display: none;
            }
        }
    }

    .cdk-program-focused .mat-sort-header-container,
    .cdk-keyboard-focused .mat-sort-header-container {
        border-bottom: none;
    }

    .mat-mdc-cell {
        font-size: toRem(13px);
    }

    .mat-mdc-row {
        background-color: $malou-color-background-white;
        cursor: pointer;
        border: toRem(1px) solid $malou-color-background-dark;
        border-radius: toRem(10px);
        margin: toRem(4px) auto;

        @include malou-respond-to('small') {
            padding: toRem(8px) 0;
            .mat-mdc-cell:last-child {
                padding: unset;
            }
        }

        .mat-mdc-cell {
            border-radius: toRem(10px);
        }
    }

    .mat-mdc-header-cell {
        color: $malou-color-text-2;
        @extend .malou-text-12--medium;
    }

    .selected {
        border: toRem(1px) solid $malou-color-border-secondary !important;
    }

    @media (hover: hover) and (pointer: fine) {
        .mat-mdc-row:hover {
            background-color: $malou-color-background-dark;
        }
    }

    @include malou-respond-to('small') {
        .mat-mdc-row:active {
            background-color: $malou-color-background-dark;
        }
    }

    .mat-column-select {
        flex: unset;
        width: 60px;
    }

    .mat-column-edit {
        justify-content: end;
        flex: 0.5;
    }

    // responsive
    @include malou-respond-to('small') {
        .mat-mdc-cell:not(:first-child) {
            display: unset; // to fix truncate with three dots
        }
        .mat-mdc-row {
            display: grid;
            min-height: unset;
            grid-template-columns: toRem(60px) repeat(1, auto) toRem(40px);
            grid-template-rows: repeat(auto, auto);
            grid-row-gap: toRem(12px);
        }
        .mat-mdc-cell:nth-child(1) {
            grid-area: 1 / 1 / 2 / 2;
            height: 100%; // to fix the height of the checkbox issue
        }
        .mat-mdc-cell:nth-child(n + 2):nth-last-child(n + 2) {
            grid-area: auto / 2 / auto / 3;
        }
        .mat-mdc-cell:nth-last-child(1) {
            grid-area: 1 / 3 / 2 / 4;
        }
    }

    .mdc-data-table__cell,
    .mdc-data-table__header-cell {
        padding: 0;
    }
}

// to apply on <mat-table> element
.malou-mat-table-rows-x-gap {
    .mat-mdc-row,
    mat-header-row {
        column-gap: toRem(12px);
    }
}

.malou-mat-table-cell-fit-content {
    flex-grow: 0;
    flex-basis: fit-content;
}

.malou-mat-table-cell-xsmall {
    // By default, a material-cell got 'flex: 1'
    flex: 0.5;
}

.malou-mat-table-cell-small {
    // By default, a material-cell got 'flex: 1'
    flex: 0.75;
}

.malou-mat-table-cell-large {
    // By default, a material-cell got 'flex: 1'
    flex: 1.25;
}

.malou-mat-table-cell-xlarge {
    // By default, a material-cell got 'flex: 1'
    flex: 1.5;
}

.malou-mat-table-cell-xxlarge {
    // By default, a material-cell got 'flex: 1'
    flex: 2;
}

.malou-mat-table-cell-xxxlarge {
    // By default, a material-cell got 'flex: 1'
    flex: 2.5;
}

.malou-mat-table-cell-xxxxlarge {
    // By default, a material-cell got 'flex: 1'
    flex: 3;
}
