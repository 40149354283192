@use '_malou_variables' as *;

.mdc-radio {
    --mat-radio-ripple-color: #{$malou-color-border-secondary};
    --mat-radio-checked-ripple-color: #{$malou-color-border-secondary};

    .mdc-radio__outer-circle {
        --mdc-radio-disabled-selected-icon-color: #{$malou-color-border-secondary};
        --mdc-radio-disabled-unselected-icon-color: #{$malou-color-border-secondary};
        --mdc-radio-unselected-focus-icon-color: #{$malou-color-border-secondary};
        --mdc-radio-unselected-hover-icon-color: #{$malou-color-border-secondary};
        --mdc-radio-unselected-icon-color: #{$malou-color-border-secondary};
        --mdc-radio-unselected-pressed-icon-color: #{$malou-color-border-secondary};
        --mdc-radio-selected-focus-icon-color: #{$malou-color-primary};
        --mdc-radio-selected-hover-icon-color: #{$malou-color-primary};
        --mdc-radio-selected-icon-color: #{$malou-color-primary};
        --mdc-radio-selected-pressed-icon-color: #{$malou-color-primary};
    }

    .mdc-radio__inner-circle {
        transform: scale(0.2) !important;
        border: none;
        color: transparent !important;
        --mat-radio-label-text-color: transparent;
        --mdc-radio-disabled-selected-icon-color: #{$malou-color-background-white};
        --mdc-radio-disabled-unselected-icon-color: transparent;
        --mdc-radio-unselected-hover-icon-color: transparent;
        --mdc-radio-unselected-icon-color: transparent;
        --mdc-radio-unselected-pressed-icon-color: transparent;
        --mdc-radio-selected-focus-icon-color: #{$malou-color-background-white};
        --mdc-radio-selected-hover-icon-color: #{$malou-color-background-white};
        --mdc-radio-selected-icon-color: #{$malou-color-background-white};
        --mdc-radio-selected-pressed-icon-color: #{$malou-color-background-white};
    }
}

.mat-mdc-radio-checked .mdc-radio__outer-circle {
    background-color: $malou-color-primary;
}

.mat-mdc-radio-button .mdc-label {
    justify-content: center;
    color: $malou-color-text-2;
    padding-left: 0;
}

.malou-small-radio-button .mdc-radio {
    transform: scale(0.75);
}

.mat-mdc-radio-disabled,
.mat-mdc-radio-disabled .mdc-label {
    cursor: not-allowed !important;
}
