@use '_malou_functions' as *;
@use '_malou_variables' as var;

body {
    scrollbar-color: var.$malou-scrollbar-color var.$malou-color-background-dark;
}

::-webkit-scrollbar {
    width: toRem(5px);
}

::-webkit-scrollbar:horizontal {
    height: toRem(5px);
}

::-webkit-scrollbar-track {
    background-color: var.$malou-color-background-dark;
    -webkit-border-radius: toRem(20px);
    border-radius: toRem(20px);
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: toRem(20px);
    border-radius: toRem(20px);
    background: var.$malou-scrollbar-color;
}

// When making changes, also check `@mixin hide-scrollbar` which does the same thing
.hide-scrollbar {
    // WebKit or Chromium based browsers
    &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
    }

    // Internet Explorer and Microsoft Edge, before Chromium
    -ms-oveflow-style: none;

    // Firefox
    scrollbar-width: none;
}
