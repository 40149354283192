@use '_malou_variables' as var;

.malou-spin {
    animation: spin 1s infinite;
}

.malou-animation-scale {
    animation: scaleAndFadein 0.2s ease-in-out;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(-360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(-360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@keyframes appearFromBottom {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes disappearToBottom {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(100%);
    }
}

@keyframes scaleAndFadein {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

// Three blinking dots animation
.blinking-dots,
.blinking-dots::before,
.blinking-dots::after {
    width: 8px;
    height: 8px;
    animation: blinking-dots 0.6s infinite linear alternate;
    background-color: var.$malou-color-primary;
    border-radius: 5px;
}

.blinking-dots {
    position: relative;
    animation-delay: 0.3s;
}
.blinking-dots::before,
.blinking-dots::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}
.blinking-dots::before {
    left: -15px;
    animation-delay: 0s;
}
.blinking-dots::after {
    left: 15px;
    animation-delay: 0.6s;
}

@keyframes blinking-dots {
    0% {
        background-color: var.$malou-color-primary;
    }
    50%,
    100% {
        background-color: var.$malou-color-primary--disabled;
    }
}
