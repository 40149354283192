@use '_malou_variables' as *;
@use '_malou-typography' as *;
@use '_malou_functions' as *;
@use '_malou_colors' as *;

.mdc-button {
    --mdc-protected-button-label-text-tracking: normal;
}

.mat-mdc-button {
    --mdc-text-button-label-text-tracking: normal;
}

.mat-mdc-unelevated-button {
    --mdc-filled-button-label-text-tracking: normal;
}

// OLD STYLES
// ----------------------------------------------------------------
.malou-button {
    font-size: 13px;
    font-weight: 600;

    a {
        @extend .malou-color-carolina;
    }
}

.malou-button--quaternary {
    @extend .malou-button;
    @extend .malou-color-leslie-light;

    :hover {
        text-decoration: underline;
    }
}

.malou-button--tertiary-underlined {
    @extend .malou-button;
    @extend .malou-color-carolina;

    :hover {
        text-decoration: underline;
    }
}

.malou-button--tertiary {
    @extend .malou-button;
    @extend .malou-color-carolina;
}

.malou-button--secondary {
    @extend .malou-button;
    @extend .malou-color-carolina;

    border: 1px solid $malou-color-carolina;
}

.malou-button--accent {
    @extend .malou-button;
    @extend .malou-color-bg-carolina;
    box-shadow: 0px 4px 10px rgba(126, 173, 202, 0.24);

    &:disabled {
        background-color: $malou-color-grey-light;
        color: $malou-color-white;
    }
}

.malou-button--warning {
    @extend .malou-button;
    @extend .malou-color-bg-warning-button;
}

.malou-button--warning--secondary {
    @extend .malou-button;
    @extend .malou-color-fail;
}

.malou-upper-left-button {
    @extend .malou-button--accent;
    border-radius: 6px;
    font-size: 13px;
    margin-left: 10px;
    height: 40px;
    min-width: 225px;
}

.malou-upper-left-button--tertiary {
    @extend .malou-button--tertiary;
    border-radius: 6px;
    font-size: 13px;
    margin-left: 10px;
    height: 40px;
    width: 225px;
}

.malou-upper-left-button__span {
    width: 100%;
}

.malou-sticky-button-modal {
    position: sticky !important;
    bottom: 10px;
    z-index: 1;
}

.malou-sticky-validate-block-modal {
    background: $malou-color-white;
    bottom: 0;
    margin: 0 30px;
    padding: 10px 0;
    position: sticky !important;
    z-index: 5;
}

// New STYLES
// ----------------------------------------------------------------

// MIXINS
// ------------------------------------

@mixin disabled($textColor) {
    &:disabled {
        color: $textColor !important;
        --mdc-protected-button-disabled-label-text-color: #{$textColor};
        --mdc-text-button-disabled-label-text-color: #{$textColor};
        filter: opacity(0.5);
        cursor: not-allowed;

        &:hover::before {
            opacity: 0;
        }
    }
}

@mixin hover-gradient-animation(
    $extendedClass,
    $hoverColor: $malou-color-gradient-btn--hover,
    $transition: 0.3s,
    $z-index-hover-value: -1
) {
    &::before {
        @extend #{$extendedClass};
        padding: 0 !important;
        margin: 0 !important;
        content: '';
        position: absolute !important;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #{$hoverColor};
        opacity: 0;
        z-index: -1;
        transition: opacity #{$transition} ease-in-out;
        box-shadow: none;
        border-radius: inherit;
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            box-shadow: none;
        }

        &:hover::before {
            opacity: 1;
            z-index: #{$z-index-hover-value};
        }
    }
}

// Raised Buttons
// ----------------------------------------
.mat-mdc-button.malou-btn,
.mat-mdc-raised-button.malou-btn,
.mat-mdc-icon-button.malou-btn {
    @extend .malou-text-12--semibold;
    padding: toRem(7px) toRem(20px);
    border-radius: toRem(10px);
    border: none;
    position: relative;
}

.mat-mdc-raised-button.malou-btn-raised {
    @extend .malou-btn;

    &--primary {
        @extend .malou-btn-raised;
        background: $malou-color-gradient-btn;
        box-shadow: 0px 4px 10px 0px rgba($malou-color-primary, 0.2) !important;
        color: $malou-color-white;
        --mdc-protected-button-label-text-color: #{$malou-color-white};

        @include hover-gradient-animation($extendedClass: '.malou-btn-raised', $z-index-hover-value: 0);
        @include disabled($malou-color-white);
        &.no-gradient {
            background: $malou-color-primary;
            box-shadow: 0px 4px 10px 0px rgba($malou-color-primary, 0.2);
            color: $malou-color-white;
            --mdc-protected-button-label-text-color: #{$malou-color-white};
            @include disabled($malou-color-white);
        }
    }

    &--secondary {
        @extend .malou-btn-raised;
        background: $malou-color-background-light;
        --mdc-protected-button-container-color: #{$malou-color-background-light};
        color: $malou-color-primary;
        --mdc-protected-button-label-text-color: #{$malou-color-primary};
        box-shadow: none;
        border: 1px solid $malou-color-background-dark;
        transition: background-color 0.3s ease-in-out;

        &:hover {
            background-color: $malou-color-background-white;
            box-shadow: none;
        }

        &:disabled {
            color: rgba($malou-color-primary, 0.2);
            --mdc-protected-button-label-text-color: rgba(#{$malou-color-primary}, 0.2);
            border: 1px solid $malou-color-background-dark;
            background: $malou-color-background-light;
            opacity: 0.8;
        }

        &--alt {
            @extend .malou-btn-raised--secondary;
            background-color: $malou-color-background-white;
            --mdc-protected-button-container-color: #{$malou-color-background-white};
            border: 1px solid $malou-color-background-dark;

            &:hover {
                background-color: $malou-color-background-dark;
                box-shadow: none;
            }

            &:disabled {
                background: $malou-color-background-white;
                opacity: 0.8;
            }
        }
    }

    &--dark {
        @extend .malou-btn-raised;
        @extend .malou-text-18--semibold;
        background: $malou-color-text-1;
        --mdc-protected-button-container-color: #{$malou-color-text-1};
        color: $malou-color-white;
        --mdc-protected-button-label-text-color: #{$malou-color-white};
        box-shadow: none;
        border: 1px solid $malou-color-text-1;
        transition: background-color 0.3s ease-in-out;

        &:hover {
            background-color: $malou-color-text-1;
            box-shadow: none;
        }

        &:disabled {
            color: rgba($malou-color-white, 0.2);
            --mdc-protected-button-label-text-color: rgba(#{$malou-color-white}, 0.2);
            border: 1px solid $malou-color-text-1;
            background: $malou-color-text-1;
            .mdc-button__label {
                opacity: 0.8;
            }
        }
    }

    &--filter-primary {
        @extend .malou-btn-raised;
        color: $malou-color-primary;
        --mdc-protected-button-label-text-color: #{$malou-color-white};
        background: $malou-color-gradient-2;
        box-shadow: none;
        transition: background-color 0.3s ease-in-out;

        &::before {
            @extend .malou-btn-raised;
            padding: 0;
            margin: 0;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: $malou-color-gradient-3;
            opacity: 0;
            z-index: -1;
            transition: opacity 0.3s ease-in-out;
        }

        &:hover::before {
            opacity: 1;
        }

        &:disabled {
            color: $malou-color-white;
            --mdc-protected-button-label-text-color: #{$malou-color-white};
            background: $malou-color-gradient-2;
            opacity: 0.5;
        }
    }

    &.btn-xl {
        padding: 14px 20px;
        line-height: 22px;
    }

    &.btn-xs {
        padding: 7px 10px;
        line-height: 22px;
        font-size: 10px;
        height: 30px;
        border-radius: 5px;
    }
}

// Flat buttons
// ----------------------------------------

.mat-mdc-button.malou-btn-flat,
.malou-btn-flat {
    @extend .malou-text-12--semibold;

    color: $malou-color-primary;
    --mdc-text-button-label-text-color: #{$malou-color-primary};
    --mdc-icon-button-icon-size: 100%;
    // Disabled
    @include disabled($malou-color-primary);

    &.btn-sm {
        display: contents;
        font-size: 10px;
        .mat-mdc-button-touch-target,
        .mat-mdc-focus-indicator {
            display: none !important;
        }
    }
}

// Icon Buttons
// ----------------------------------------
.mat-mdc-icon-button.malou-btn-icon {
    border-radius: 8px;
    line-height: unset;
    padding: 7px;

    mat-icon {
        height: 17px;
        width: 17px;
        --mdc-icon-button-icon-size: 100%;
    }

    height: 43px;
    width: 43px;

    justify-content: center;
    align-items: center;
    display: flex;

    &--primary {
        @extend .malou-btn-icon;
        @include hover-gradient-animation('.malou-btn-raised');

        background: $malou-color-gradient-btn;
        color: $malou-color-white;
        --mdc-icon-button-icon-color: #{$malou-color-white};
        transition: background-color 0.3s ease-in-out;

        &:hover {
            background-color: $malou-color-primary--hover;
        }

        &:disabled {
            opacity: 0.7;
            color: $malou-color-white;
            --mdc-icon-button-disabled-icon-color: #{$malou-color-white};
            cursor: not-allowed;
        }
    }

    &--secondary {
        @extend .malou-btn-icon;
        background: $malou-color-white;
        color: $malou-color-primary;
        --mdc-icon-button-icon-color: #{$malou-color-primary};
        border: 1px solid $malou-color-border-primary;
        transition: background-color 0.3s ease-in-out;

        &:hover {
            background-color: $malou-color-background-light;
        }

        &:disabled {
            opacity: 0.5;
            color: $malou-color-primary;
        }

        &.btn-active {
            background: $malou-color-background-light;
            border: 1px solid $malou-color-border-secondary;
        }
    }

    &--alt {
        @extend .malou-btn-icon;
        background: $malou-color-background-light;
        --mdc-protected-button-container-color: #{$malou-color-background-light};
        border: 1px solid $malou-color-background-dark;

        &:hover {
            background-color: $malou-color-background-white;
            box-shadow: none;
        }

        &:disabled {
            background: $malou-color-background-light;
            opacity: 0.8;
        }
    }

    &.btn-xl {
        height: 50px;
        width: 50px;
    }

    &.btn-sm {
        height: 30px;
        width: 30px;
    }
}

// Toggle Buttons
// ----------------------------------------
.malou-single-toggle-icon-btn {
    border: none !important;
    border-radius: 8px !important;

    .mat-pseudo-checkbox {
        display: none;
    }

    --mat-standard-button-toggle-label-text-tracking: normal;

    &--primary {
        @extend .malou-single-toggle-icon-btn;
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        mat-button-toggle {
            background: $malou-color-gradient-btn;
            color: $malou-color-white;
            --mat-standard-button-toggle-text-color: #{$malou-color-white};
            --mat-standard-button-toggle-disabled-state-text-color: #{$malou-color-white};
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        .mat-button-toggle-checked {
            background: $malou-color-gradient-btn--hover;
            color: $malou-color-white;
            --mat-standard-button-toggle-selected-state-text-color: #{$malou-color-white};
            --mat-standard-button-toggle-disabled-selected-state-text-color: #{$malou-color-white};
            opacity: 0.7;
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
            .mat-button-toggle-focus-overlay {
                border: none;
            }
        }
    }

    &--secondary {
        @extend .malou-single-toggle-icon-btn;
        border: 1px solid $malou-color-border-primary;
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        mat-button-toggle {
            background: $malou-color-background-white;
            color: $malou-color-primary;
            --mat-standard-button-toggle-text-color: #{$malou-color-primary};
            --mat-standard-button-toggle-disabled-state-text-color: #{$malou-color-primary};
            transition: background-color 0.3s ease-in-out;

            &:hover {
                background-color: $malou-color-background-dark;
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
        .mat-button-toggle-checked {
            background: $malou-color-background-dark !important;
            color: $malou-color-primary !important;
            --mat-standard-button-toggle-selected-state-text-color: #{$malou-color-primary};
            --mat-standard-button-toggle-disabled-selected-state-text-color: #{$malou-color-primary};
            border: 1px solid $malou-color-primary !important;
            border-radius: 8px !important;
            /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
            .mat-button-toggle-focus-overlay {
                border: none;
            }
        }
    }

    &--tertiary {
        @extend .malou-single-toggle-icon-btn;

        mat-button-toggle {
            background: $malou-color-background-white;
            border: 1px solid $malou-color-border-primary;
            color: $malou-color-primary;
            --mat-standard-button-toggle-text-color: #{$malou-color-primary};
            --mat-standard-button-toggle-disabled-state-text-color: #{$malou-color-primary};

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    background-color: $malou-color-background-dark;
                }
            }
        }

        .mat-button-toggle-checked {
            background: $malou-color-gradient-btn;
            border: 0;
            color: $malou-color-white;
            --mat-standard-button-toggle-selected-state-text-color: #{$malou-color-white};
            --mat-standard-button-toggle-disabled-selected-state-text-color: #{$malou-color-white};
        }
    }
}

.malou-group-toggle-btn {
    border: none;
    border-radius: 8px !important;
    border: 1px solid $malou-color-border-primary;
    height: toRem(50px);
    box-sizing: border-box;

    mat-button-toggle {
        --mat-standard-button-toggle-label-text-tracking: normal;
        --mat-standard-button-toggle-label-text-size: 12px;
        --mat-standard-button-toggle-label-text-weight: 600;

        background: $malou-color-background-white;
        color: $malou-color-primary;
        transition: background-color 0.3s ease-in-out;
        border: none !important;
        height: toRem(48px) !important;

        &:hover {
            background-color: $malou-color-background-dark;
        }

        mat-icon {
            height: toRem(14px);
            width: toRem(14px);
        }

        .mat-button-toggle-label-content {
            padding: 0 toRem(18px);
        }

        .mat-button-toggle-button {
            height: toRem(49px);
        }

        .mat-pseudo-checkbox {
            display: none;
        }
    }

    .mat-button-toggle-checked {
        background: $malou-color-gradient-btn !important;
        color: $malou-color-white !important;

        .mat-button-toggle-focus-overlay {
            border: none;
        }

        border: none;

        &:hover {
            background-color: none;
        }
    }
}

.malou-group-toggle-mobile-btn {
    border: none;
    border-radius: 12px !important;
    border: 1px solid $malou-color-border-primary;
    height: 40px;
    display: flex !important;
    flex-direction: row;
    box-shadow: $malou-shadow-1--more-opaque;
    background: $malou-color-background-white;

    mat-button-toggle {
        --mat-standard-button-toggle-label-text-size: 14px;
        --mat-standard-button-toggle-label-text-weight: 600;

        flex: 1;
        background: $malou-color-background-white;
        color: $malou-color-primary;
        transition: background-color 0.3s ease-in-out;
        border-left: none !important;
        border-radius: 12px !important;
        overflow: hidden;

        &:hover {
            background-color: $malou-color-background-dark;
        }

        .mat-button-toggle-label-content {
            padding: 0 toRem(18px);
        }

        .mat-button-toggle-button {
            height: toRem(50px);
        }

        button {
            height: 40px !important;
        }

        span {
            line-height: 40px !important;
        }

        .mat-pseudo-checkbox {
            display: none;
        }
    }

    .mat-button-toggle-checked {
        background: $malou-color-gradient-btn !important;
        border-radius: 12px !important;
        color: $malou-color-white !important;

        .mat-button-toggle-focus-overlay {
            border: none;
        }

        border: none;

        &:hover {
            background-color: none;
        }
    }
}

// ----------------------------------------
// UTILITY CLASSES
// ----------------------------------------
.btn-background-reversed {
    background: $malou-color-background-white;
    color: $malou-color-primary;
    --mdc-protected-button-label-text-color: #{$malou-color-primary};
    border: 1px solid $malou-color-background-dark;

    &:hover {
        background-color: $malou-color-background-dark;
    }
}

.mat-mdc-button-persistent-ripple,
.mat-mdc-button-ripple {
    display: none;
}

.mat-datepicker-toggle .mat-mdc-button-base {
    width: 40px;
    height: 40px;
    padding: 8px 0;
}

.mdc-button__label {
    width: 100%;
}

.mat-mdc-raised-button:not(:disabled):not(.malou-btn-raised--primary):active,
.mat-mdc-raised-button.mdc-ripple-upgraded--background-focused,
.mat-mdc-raised-button:not(.mdc-ripple-upgraded):not(.malou-btn-raised--primary):focus {
    box-shadow: none !important;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
    --mat-standard-button-toggle-divider-color: #{$malou-color-background-dark};
}
