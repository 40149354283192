// Poppins
@font-face {
    font-family: 'Poppins';
    src: url(/assets/fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Poppins';
    src: url(/assets/fonts/Poppins/Poppins-Bold.ttf) format('truetype');
    font-weight: bold;
}
@font-face {
    font-family: 'Poppins';
    src: url(/assets/fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
    font-weight: 600;
}
@font-face {
    font-family: 'Poppins';
    src: url(/assets/fonts/Poppins/Poppins-Medium.ttf) format('truetype');
    font-weight: 500;
}
@font-face {
    font-family: 'Poppins';
    src: url(/assets/fonts/Poppins/Poppins-Light.ttf) format('truetype');
    font-weight: 100 300;
}

// Montserrat
@font-face {
    font-family: 'Montserrat';
    src: url(/assets/fonts/Montserrat/Montserrat-Regular.otf) format('truetype');
}

// Passion One
@font-face {
    font-family: 'Passion One';
    src: url(/assets/fonts/Passion_One/PassionOne-Regular.ttf) format('truetype');
    font-weight: 400 500;
}
@font-face {
    font-family: 'Passion One';
    src: url(/assets/fonts/Passion_One/PassionOne-Bold.ttf) format('truetype');
    font-weight: 600 700;
}
@font-face {
    font-family: 'Passion One';
    src: url(/assets/fonts/Passion_One/PassionOne-Black.ttf) format('truetype');
    font-weight: 800 900;
}

// Meow Script
@font-face {
    font-family: 'Meow Script';
    src: url(/assets/fonts/Meow_Script/MeowScript-Regular.ttf) format('truetype');
}

// Helvetica
@font-face {
    font-family: 'Helvetica';
    src: url(/assets/fonts/Helvetica/Helvetica-Regular.otf) format('opentype');
}
@font-face {
    font-family: 'Helvetica';
    src: url(/assets/fonts/Helvetica/Helvetica-Bold.otf) format('opentype');
    font-weight: bold;
}
@font-face {
    font-family: 'Helvetica';
    src: url(/assets/fonts/Helvetica/Helvetica-SemiBold.otf) format('opentype');
    font-weight: 600;
}
@font-face {
    font-family: 'Helvetica';
    src: url(/assets/fonts/Helvetica/Helvetica-Medium.otf) format('opentype');
    font-weight: 500;
}
@font-face {
    font-family: 'Helvetica';
    src: url(/assets/fonts/Helvetica/Helvetica-Light.otf) format('opentype');
    font-weight: 100 300;
}
