@use '_malou_variables' as *;
@use '_malou_functions' as *;
@use '_malou_mixins' as *;

.mat-mdc-option {
    --mat-option-label-text-color: #{$malou-color-text-2};
    --mat-checkbox-label-text-weight: 600;

    min-height: 36px !important;

    &.mat-mdc-option-active {
        --mat-option-selected-state-label-text-color: #{$malou-color-text-2};

        background: $malou-color-background-dark !important;
        font-weight: 600;
    }

    & > span {
        width: 100%;
    }
}

.mat-mdc-select-disabled {
    opacity: 0.5;
}

.custom-option-panel {
    box-shadow: $malou-shadow-1 !important;
    padding: toRem(9px) 0px !important;
    border-radius: toRem(10px) !important;
    background-color: $malou-color-white;

    .mat-mdc-option:hover:not(.mat-mdc-select-disabled) {
        background: $malou-color-background-dark !important;
    }

    .mat-mdc-option {
        font-size: toRem(12px) !important;
    }
}

.sort-filter-option-panel {
    @extend .custom-option-panel;
    width: calc(100% + 32px) !important;
    margin-left: -32px;
    max-width: unset !important;

    @include malou-respond-to('small') {
        width: calc(100% + 70px) !important;
    }
}

.mat-mdc-select-panel.restaurant-select-panel {
    background-color: $malou-color-background-white;
    max-height: 450px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-bottom: 0px !important;
}

.mat-mdc-select-arrow {
    border: none !important;
    height: 0 !important;
    width: 15px !important;
    transition: transform 200ms ease-in-out;

    svg {
        display: none;
    }

    &::after {
        content: '';
        position: absolute;
        top: -4px;
        left: 0;
        width: 15px;
        height: 10px;
        background: url('../../assets/icons/chevron-down-purple.svg') no-repeat;
    }
}

.mat-mdc-autocomplete-panel {
    .mat-mdc-option {
        min-height: 50px !important;

        &.mat-mdc-option-active,
        &:hover {
            --mat-option-selected-state-label-text-color: #{$malou-color-text-2};

            background: $malou-color-background-dark !important;
            font-weight: 500;
        }

        & > span {
            width: 100%;
        }
    }
}

@include malou-respond-to('small') {
    .mat-mdc-form-field-infix {
        display: flex !important;
        align-items: end !important ;
    }
    .mat-mdc-select-trigger {
        mat-select-trigger {
            top: 0px !important;
        }
    }
}
