@use '_malou_variables' as *;
@use '_malou_functions' as *;
@use '_malou-typography' as *;
@use '_malou_mixins' as *;
@use '_malou_animations' as *;

.malou-dialog {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.malou-dialog__header {
    > p:first-child {
        @extend .malou-text-18--bold;
        color: $malou-color-text-1;
    }

    > span:first-child {
        @extend .malou-text-18--bold;
        color: $malou-color-text-1;
    }

    > div:first-child {
        @extend .malou-text-18--bold;
        color: $malou-color-text-1;
    }

    .close {
        color: $malou-color-primary;
        height: 1rem !important;
        width: 1rem !important;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: toRem(26px);
}

.malou-dialog__body {
    flex-grow: 1;
    margin-right: toRem(10px);
    padding-right: toRem(16px);
    padding-left: toRem(26px);
    overflow-y: auto;
}

.malou-dialog__section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 toRem(26px);
    gap: toRem(15px);
}

.malou-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: toRem(26px);
    gap: toRem(15px);
}

// Classes below are to use in the 'panelClass' option in the MatDialog 'open' method

.malou-dialog-panel {
    max-width: none !important;

    .mdc-dialog__container {
        border-radius: 10px !important;

        --mdc-dialog-container-shape: 10px;
        --mdc-dialog-container-elevation-shadow: none !important;
    }

    @include malou-respond-to('medium') {
        position: absolute !important;
        bottom: 0;
        left: 0;
        width: 100vw !important;
        height: 80vh !important;

        .mdc-dialog__container {
            border-radius: 10px 10px 0 0 !important;
        }
    }
}

.malou-dialog-panel--large {
    max-width: none !important;

    .mat-mdc-dialog-container {
        background-color: $malou-color-background-white;
        border-radius: 10px !important;

        --mdc-dialog-container-shape: 10px;
        --mdc-dialog-container-elevation-shadow: none !important;
    }

    @include malou-respond-to('large') {
        position: absolute !important;
        bottom: 0;
        left: 0;
        width: 100vw !important;
        height: 80vh !important;

        .mat-mdc-dialog-container {
            border-radius: 10px 10px 0 0 !important;

            --mdc-dialog-container-shape: 10px 10px 0 0;
        }
    }
}

.malou-dialog-panel--full {
    max-width: none !important;
    position: absolute !important;
    bottom: 0;
    left: 0;
    width: 100vw !important;
    height: 90vh !important;
    transition: all 0.3s ease-in-out;

    .mat-mdc-dialog-container {
        background-color: $malou-color-background-white;
        border-radius: 10px !important;

        --mdc-dialog-container-shape: 10px;
        --mdc-dialog-container-elevation-shadow: none !important;
    }

    &:has(app-close-without-saving-modal) {
        height: 50vh !important;
    }

    // firefox doesn't support :has
    &:matches(app-close-without-saving-modal) {
        height: 50vh !important;
    }

    @include malou-respond-to('medium') {
        height: 80vh !important;
    }
}

.malou-dialog-panel--fit-content {
    min-height: 40vh;
    max-height: 90vh;
    height: fit-content !important;

    .mat-mdc-dialog-container {
        padding: 0 !important;
        background-color: $malou-color-background-white;
        border-radius: 10px !important;

        --mdc-dialog-container-shape: 10px;
        --mdc-dialog-container-elevation-shadow: none !important;
    }

    .mat-mdc-dialog-container {
        height: fit-content;
    }

    @include malou-respond-to('medium') {
        height: fit-content !important;
    }
}

.malou-dialog-panel--animated {
    @extend .malou-dialog-panel;

    @include malou-respond-to('medium') {
        position: absolute !important;
        bottom: 0;
        left: 0;
        width: 100vw !important;
        height: 80vh !important;
        transition: all 0.3s ease-in-out;

        .mat-mdc-dialog-container {
            border-radius: 10px 10px 0 0 !important;

            --mdc-dialog-container-shape: 10px 10px 0 0;
        }

        &:has(app-close-without-saving-modal) {
            height: 50vh !important;
        }

        &:matches(app-close-without-saving-modal) {
            height: 50vh !important;
        }
    }
}

.malou-dialog-hidden {
    .mat-mdc-dialog-container {
        background: transparent !important;
        box-shadow: none !important;
    }
}

.malou-dialog-mobile {
    @include malou-respond-to('medium') {
        max-width: none !important;
        width: 100vw !important;
        // Max height is 80vh to avoid the keyboard to hide the content and navigator searchbar
        height: 80vh !important;
        position: absolute !important;
        bottom: 0;
        left: 0;
        transition: none;
        animation: appearFromBottom 0.4s ease-in-out;

        &.close {
            animation: disappearToBottom 0.45s ease-in-out;
        }
    }
}

.malou-dialog-panel--without-border-radius {
    max-width: none !important;

    .mdc-dialog__container {
        border-radius: 0px !important;

        --mdc-dialog-container-elevation-shadow: none !important;
    }

    @include malou-respond-to('medium') {
        position: absolute !important;
        bottom: 0;
        left: 0;
        width: 100vw !important;
        height: 80vh !important;

        .mdc-dialog__container {
            border-radius: 10px 10px 0 0 !important;
        }
    }
}
