@use '_malou_functions' as *;

.malou-avatar {
    border-radius: 50%;
    flex-shrink: 0;
    object-fit: cover;
    &--xs {
        @extend .malou-avatar;
        height: toRem(20px);
        width: toRem(20px);
    }
    &--small {
        @extend .malou-avatar;
        height: toRem(30px);
        width: toRem(30px);
    }
    &--medium {
        @extend .malou-avatar;
        height: toRem(34px);
        width: toRem(34px);
    }
    &--large {
        @extend .malou-avatar;
        height: toRem(50px);
        width: toRem(50px);
    }
}
