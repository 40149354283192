@use '_malou_variables' as *;
@use '_malou_functions' as *;
@use '_malou-typography' as *;

.malou-status {
    display: flex;
    height: toRem(15px);
    width: toRem(15px);
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: $malou-color-white;

    &--check {
        @extend .malou-status;
        background: $malou-color-state-success;
        mat-icon {
            transform: scale(0.65);
        }
    }
    &--error {
        @extend .malou-status;
        background: $malou-color-chart-pink--accent;
        mat-icon {
            transform: scale(0.7);
        }
        path {
            fill: white;
        }
    }
    &--waiting {
        @extend .malou-status;
        background: $malou-color-state-warn;
        mat-icon {
            transform: scale(0.6);
        }
    }

    &--count {
        @extend .malou-status;
        @extend .malou-text-1O--medium !optional;
        height: toRem(19px);
        width: toRem(19px);
        line-height: toRem(10px);
        background: $malou-color-gradient-3;
        text-align: center;
        padding-top: toRem(5px);
        padding-bottom: toRem(4px);
    }

    &--cross {
        @extend .malou-status;
        background: $malou-color-text-2;
        mat-icon {
            transform: scale(0.65);
        }
    }
}
