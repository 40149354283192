@use '_malou_variables' as *;

.malou-post-card {
    flex: 0 1 calc(33% - 1em);
    p,
    i {
        color: $malou-color-dark;
    }
    .malou-post__picture {
        width: 100%;
        border-radius: 4px;
        margin-top: 5px;
        margin-right: 5px;
    }
    .malou-post__label {
        font-size: 15px;
        font-weight: 500;
    }
    .malou-post__caption {
        margin: 15px 0;
        height: 30px;
        i {
            font-size: 20px;
            margin-top: -20px;
        }
    }
}
.malou-file-icon {
    z-index: 1;
    position: absolute;
    top: 24px;
    right: 35%;
    height: 20px;
    content: '';
    background-color: transparent;
    background-size: 100% 100%;
    border: 0;
    padding: 8px;
    margin-top: 5px;
    &.small-post-icon {
        top: 15px;
    }

    &--carousel {
        transform: rotate(90deg);
        background-image: url('/assets/multiple-file.svg');
    }

    &--reel {
        background-image: url('/assets/reel-icon.svg');
    }
}

@media screen and (min-width: 30em) {
    .malou-post-card {
        max-width: calc(50% - 1em);
    }
}

@media screen and (min-width: 70em) {
    .malou-post-card {
        max-width: calc(33% - 1em);
    }
}
