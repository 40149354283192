@use 'malou_variables' as var;

.malou-border-carolina {
    border: 2px solid var.$malou-color-carolina;
}

.malou-border-radius-5 {
    border-radius: 5px;
}

.malou-border-primary {
    border: 1px solid var.$malou-color-border-primary !important;
}

.malou-border-primary-opaque {
    border: 1px solid var.$malou-color-primary !important;
}

.malou-border-purple-light {
    border: 1px solid var.$malou-color-purple--light;
}

.malou-border-secondary {
    border: 1px solid var.$malou-color-border-secondary;
}

.malou-border-dark {
    border: 1px solid var.$malou-color-background-dark;
}

.malou-border-error {
    border: 1px solid var.$malou-color-state-error;
}
